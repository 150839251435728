<template>
  <ItemGrid
    :columns="columns"
    :items="entities"
    :mode="mode"
    :breadcrumbs="true"
    @select="handleSelect"
    @view="handleOpenFile"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #items="{ items }">
      <div id="entity-grid">
        <div class="row q-col-gutter-md">
          <div
            :class="showFileSheet ? 'col-md-6 col-lg-6' : 'col-md-12 col-lg-12'"
          >
            <div class="row q-col-gutter-md">
              <div
                v-for="item in items"
                :key="item.id"
                :class="
                  showFileSheet ? 'col-md-6 col-lg-4' : 'col-md-3 col-lg-2'
                "
              >
                <div class="entity">
                  <!-- folder -->

                  <template v-if="item.type === 'FOLDER'">
                    <div @click="handleSelect(items, item.id)">
                      <div class="row items-center">
                        <BaseIcon name="eva-folder" color="amber" size="36px" />
                        <q-space />
                        <!-- <BaseIcon
                    name="eva-more-vertical"
                    inherit-color
                    class="icon"
                  /> -->
                      </div>

                      <div class="col q-mt-md">
                        <div class="name ellipsis">{{ item.name }}</div>
                        <div class="stats">
                          <div class="stat">Level {{ item.level }}</div>
                          <BaseIcon
                            name="mdi-circle-small"
                            inherit-color
                            class="icon"
                          />
                          <div class="stat">{{ item.files }} Files</div>
                          <!-- <BaseIcon
                            name="mdi-circle-small"
                            inherit-color
                            class="icon"
                          />
                          <div class="stat">{{ item.size }}</div> -->
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- ... -->

                  <!-- file -->

                  <template v-if="item.type === 'FILE'">
                    <div @click="handleOpenFile(item.id, item)">
                      <div class="row items-center">
                        <FileIcon
                          :mime-type="getFileType(item.name)"
                          class="file-icon"
                        />
                        <q-space />
                        <BaseIcon
                          v-if="item.isPassword"
                          v-tooltip.top="'Protected'"
                          name="eva-lock"
                          color="red"
                          class="icon"
                        />
                        <BaseIcon
                          v-if="item.maxVersion === 1"
                          v-tooltip.top="'Primary Document'"
                          name="mdi-tag"
                          color="primary"
                          class="icon"
                        />
                        <BaseIcon
                          v-if="item.maxVersion === 0"
                          v-tooltip.top="'V' + item.version"
                          name="mdi-tag-outline"
                          color="secondary"
                          class="icon"
                        />
                      </div>

                      <div class="col q-mt-md">
                        <div class="name ellipsis">{{ item.name }}</div>
                        <div class="stats ellipsis">
                          <div class="stat">{{ item.createdBy }}</div>
                          <BaseIcon
                            name="mdi-circle-small"
                            inherit-color
                            class="icon"
                          />
                          <div class="stat">{{ item.size }}</div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- ... -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" class="col-md-6 col-lg-6">
            <div id="file-sheet">
              <!-- header -->

              <div class="header">
                <div class="title-1">
                  <div id="file">
                    <div class="avatar">
                      <FileIcon :mime-type="selectedFile.type" />
                    </div>

                    <div class="q-ml-md">
                      <div class="filename">
                        {{ selectedFile.name }}
                      </div>

                      <div class="meta">
                        <div>
                          Archived At: {{ $day.format(selectedFile.createdAt) }}
                        </div>
                        <BaseIcon name="mdi-circle-small" />
                        <div>Archived By: {{ selectedFile.createdBy }}</div>
                        <BaseIcon name="mdi-circle-small" />
                        <div>File Size: {{ selectedFile.size }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <q-space />

                <BaseActionButton
                  is-flat
                  icon="eva-expand-outline"
                  class="q-mr-sm"
                  @click="handleOpenFullFileSheet(selectedFile.itemId)"
                />

                <BaseCloseButton @click="showFileSheet = false" />
              </div>

              <!-- ... -->

              <!-- content -->

              <BaseScrollbar id="file-content" height="270px">
                <div class="file-list">
                  <iframe
                    id="file-frame"
                    :src="fileLink"
                    frameborder="0"
                    height="100%"
                  ></iframe>
                </div>
              </BaseScrollbar>

              <!-- ... -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";

export default {
  name: "EntityGrid",

  components: { ItemGrid, FileIcon },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    entities: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    hideFileSheet: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gridType: "",
      selectedFile: {},
      showFileSheet: false,
    };
  },

  computed: {
    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.itemId}&type=2`;
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.itemId}&type=2&filename=${this.selectedFile.name}`;
          } else if (origin === "https://app.ezofis.com1") {
            return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.itemId}&type=2`;
          } else {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.itemId}&type=2&filename=${this.selectedFile.name}`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.itemId}&type=2&filename=${this.selectedFile.name}`;
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.itemId}/2`;
        }
      } else {
        return "";
      }
    },
  },

  watch: {
    entities() {
      // if (this.entities.length) {
      //   if (this.entities[0].data)
      //     this.gridType = this.entities[0].data[0].type;
      //   if (this.gridType !== "FILE") {
      //     // this.showFileSheet = false;
      //   }
      // }
    },

    hideFileSheet() {
      if (!this.hideFileSheet) {
        this.showFileSheet = false;
        // this.hideFileSheet = true;
      }
    },
  },

  methods: {
    handleSelect(entity, entityId) {
      this.$emit("select", entityId, entity);
    },

    handleEdit(entityId) {
      this.$emit("edit", entityId);
    },

    handleDelete(entityId) {
      this.$emit("delete", entityId);
    },

    handleRestore(entityId) {
      this.$emit("restore", entityId);
    },

    handleMore(entityId) {
      this.$emit("more", entityId);
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0];
    },

    handleOpenFile(itemId, item) {
      if (item) {
        this.selectedFile = {};
        this.showFileSheet = true;
        this.selectedFile = {
          name: item.name,
          size: item.size,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          id: item.id,
          type: item.name.split(".")[1],
          isPassword: item.isPassword,
          passwordCreatedBy: item.passwordCreatedBy,
        };
        this.$emit("minimumFileSheet", this.selectedFile);
      }
    },

    handleOpenFullFileSheet(itemId) {
      this.$emit("view", itemId);
    },
  },
};
</script>

<style lang="scss" scoped>
#entity-grid {
  .entity {
    background-color: var(--component-bg-color);
    border-radius: 4px;
    padding: 16px;
    height: 100%;
    cursor: pointer;

    .name {
      font-weight: 600;
      margin-bottom: 4px;
    }

    .icon {
      color: var(--icon-color-inverted);
    }

    .file-icon {
      height: 36px;
      width: 36px;
    }

    .stats {
      display: flex;
      align-items: center;

      .stat {
        @extend .text-sm;
        color: var(--icon-color);
      }
    }

    .ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }
  }
}

#file-sheet {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  cursor: pointer;

  .header {
    display: flex;
    align-items: center;
    // padding: 0px 10px;
    // height: 60px;
    border-bottom: 1px solid var(--divider-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#file {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#file-content {
  // min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}
</style>
