<template>
  <div id="repositories-browse">
    <Portal to="primary-action">
      <BaseButton
        v-if="showAddRepository"
        icon="eva-plus"
        label="add folder"
        class="q-ml-md"
        @click="addRepository"
      />
    </Portal>

    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="repository"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        :simple-search-applyed.sync="simpleSearchApplyed"
        @refresh="getRepositories"
        @search="searchRow"
        @update="getRepositories"
        @export="export_Data"
      />
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          :simple-search-applyed="simpleSearchApplyed"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <RepositoryGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          :security="false"
          @edit="editRepository"
          @more="showRepositoryDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @select="openRepository"
          @secure="secureRepository"
        />

        <RepositoryList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          :security="false"
          @edit="editRepository"
          @more="showRepositoryDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @select="openRepository"
          @secure="secureRepository"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="eva-archive-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
    />

    <RepositoryDetails
      v-if="isRepositoryDetailsVisible"
      v-model="isRepositoryDetailsVisible"
      :columns="columns"
      :repository="selectedRepository"
      @edit="editRepository"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteRepository
      v-model="isDeleteConfirmationVisible"
      :repository="selectedRepository"
      @delete="updateRepository"
    />

    <ConfirmRestoreRepository
      v-model="isRestoreConfirmationVisible"
      :repository="selectedRepository"
      @restore="updateRepository"
    />
  </div>
</template>

<script>
import { startCase, capitalize } from "lodash-es";
import { repository } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
// import formatBytes from "@/helpers/format-bytes.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import RepositoryGrid from "./components/RepositoryGrid.vue";
import RepositoryList from "./components/RepositoryList.vue";
import RepositoryDetails from "./components/RepositoryDetails.vue";
import ConfirmDeleteRepository from "./components/ConfirmDeleteRepository.vue";
import ConfirmRestoreRepository from "./components/ConfirmRestoreRepository.vue";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "RepositoriesBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActions,
    ItemActionChips,
    RepositoryGrid,
    RepositoryList,
    RepositoryDetails,
    Pagination,
    ConfirmDeleteRepository,
    ConfirmRestoreRepository,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "workspace",
          label: "Workspace",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fieldsType",
          label: "Type",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          options: [
            {
              id: this.$nano.id(),
              label: "Static",
              value: "STATIC",
            },
            {
              id: this.$nano.id(),
              label: "Dynamic",
              value: "DYNAMIC",
            },
          ],
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "files",
          label: "Files",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "size",
          label: "Size",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "cloudFileServer",
          label: "Cloud File Server",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "localFileServerPath",
          label: "Local File Server Path",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "owner",
          label: "Owner",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "coordinator",
          label: "Coordinator",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "expiryDate",
          label: "Expiry Date",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "versionType",
          label: "Version Type",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          options: [
            {
              id: this.$nano.id(),
              label: "Timestamp",
              value: "TIMESTAMP",
            },
            {
              id: this.$nano.id(),
              label: "Number",
              value: "NUMBER",
            },
            {
              id: this.$nano.id(),
              label: "Alphabet",
              value: "ALPHABET",
            },
          ],
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "versionedDocuments",
          label: "Versioned Documents",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "fields",
          label: "Fields",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          isFilter: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.DATE,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: false,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.DATE,
        },
      ],
      repositories: [],
      activeView: "GRID",
      selectedRepository: {},
      isRepositoryDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      repositoriesFilterData: [],
      repositoriesData: [],
      simpleSearchApplyed: false,
    };
  },

  computed: {
    items() {
      return this.repositories.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((repository) => ({
          id: repository.id,
          name: repository.name,
          description: repository.description,
          workspaceId: repository.workspace?.id,
          workspace: repository.workspace?.value,
          fieldsType: startCase(capitalize(repository.fieldsType)),
          files: repository.filesCount || 0,
          size: "", //formatBytes(repository.fileSize),
          cloudFileServer: startCase(capitalize(repository.cloudFileServer)),
          localFileServerPath: repository.localFileServerPath,
          owner: repository.ownerlist,
          coordinator: repository.coordinatorlist,
          expiryDate: repository.expiryDate,
          versionType: startCase(capitalize(repository.versionType)),
          versionedDocuments: startCase(
            capitalize(repository.versionedDocuments)
          ),
          fields: repository.fields,
          icon: "eva-archive",
          createdBy: repository.createdBy,
          createdAt: this.$day.format(repository.createdAt),
          modifiedBy: repository.modifiedBy,
          modifiedAt: this.$day.format(repository.modifiedAt),
          actionButton: true,
        })),
      }));
    },

    showAddRepository() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.folders).Menu[
          "Manage Folders"
        ];

      return false;
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getRepositories(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    findRepository(repositoryId) {
      const repositories = [];
      this.items.forEach((item) => repositories.push(...item.data));

      return repositories.find((repository) => repository.id === repositoryId);
    },

    showRepositoryDetails(repositoryId) {
      this.selectedRepository = this.findRepository(repositoryId);
      this.isRepositoryDetailsVisible = true;
    },

    editRepository(repositoryId) {
      this.$router.push({
        name: "repository-builder",
        params: { id: String(repositoryId) },
      });
    },

    showDeleteConfirmation(repositoryId) {
      this.selectedRepository = this.findRepository(repositoryId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(repositoryId) {
      this.selectedRepository = this.findRepository(repositoryId);
      this.isRestoreConfirmationVisible = true;
    },

    openRepository(repositoryId) {
      const workspaceId = this.findRepository(repositoryId).workspaceId;
      this.$router.push({
        query: { workspaceId, repositoryId },
      });
      this.filterBy = [];
    },

    addRepository() {
      this.$router.push({ name: "repository-builder" });
    },

    secureRepository(repositoryId) {
      this.$router.push({
        name: "repository-secure",
        params: { id: String(repositoryId) },
      });
    },

    searchRow(search) {
      if (search) {
        this.repositories = this.repositoriesFilterData.map(
          ({ key, value }) => ({
            key: key,
            value: value.filter((row) => {
              for (let cell in row) {
                let index = this.columns.findIndex((column) => {
                  return column.name === cell;
                });
                if (index >= 0 && row[cell]) {
                  if (
                    String(row[cell])
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return row;
                  }
                }
              }
            }),
          })
        );
      } else {
        this.repositories = this.repositoriesData;
        this.repositoriesFilterData = this.repositoriesData;
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getRepositories();
    },

    /* api functions */

    async getRepositories() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await repository.getRepositories({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      // if (data.length) {
      //   let taskRepository = data[0].value.find((row) => row.name == "TASKS");
      //   if (taskRepository) {
      //     meta.totalItems = meta.totalItems - 1;
      //   }
      //   data[0].value = data[0].value.filter((row) => row.name != "TASKS");
      // }
      this.repositoriesData = data || [];
      this.repositoriesFilterData = data || [];
      this.repositories = data || [];

      this.totalItems = meta?.totalItems || 0;
    },

    async updateRepository(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await repository.updateRepository(
        this.selectedRepository.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      this.$alert.success(
        `Repository "${this.selectedRepository.name}" ${mode} successfully`
      );

      this.getRepositories();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await repository.getRepositories({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((repository) => {
          let fieldList = [];
          repository.fields.forEach((field) => {
            fieldList.push({
              Name: field.name,
              Type: field.dataType,
              Mandatory: field.isMandatory,
              Level: field.level,
            });
          });
          let owners = [];
          if (repository.ownerlist) {
            repository.ownerlist.forEach((row) => {
              owners.push(row.value);
            });
          }
          let coordinators = [];
          if (repository.coordinatorlist) {
            repository.coordinatorlist.forEach((row) => {
              coordinators.push(row.value);
            });
          }
          exportRecords.push({
            Name: repository.name,
            Description: repository.description,
            Workspace: repository.workspace?.value,
            "Fields Type": startCase(capitalize(repository.fieldsType)),
            "Files Count": repository.filesCount || 0,
            // "Total File Size": formatBytes(repository.fileSize),
            Owner: owners.toString(),
            Coordinator: coordinators.toString(),
            "Expiry Date": repository.expiryDate,
            "Version Type": startCase(capitalize(repository.versionType)),
            "Versioned Docuemnts": startCase(
              capitalize(repository.versionedDocuments)
            ),
            Fields: JSON.stringify(fieldList),
            "Created By": repository.createdBy,
            "Created At": this.$day.format(repository.createdAt),
            "Last Modified By": repository.modifiedBy,
            "Last Modified At": this.$day.format(repository.modifiedAt),
          });
        });
        let exportName = "Repository List - Report";
        if (this.mode === "TRASH") {
          exportName = "Repository Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repositories-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
