<template>
  <div id="entityList">
    <ItemList
      :columns="columns"
      :items="entities"
      :mode="mode"
      :checkbox="checkbox"
      :repository-id="repositoryId"
      :has-meta-data-edit="hasMetaDataEdit"
      :breadcrumbs="true"
      :repository="true"
      @select="handleSelect"
      @view="handleOpenFile"
      @edit="handleEdit"
      @delete="handleDelete"
      @restore="handleRestore"
      @more="handleMore"
      @showThumbnail="showFileThumbnail"
      @hideThumbnail="hideThumbnail"
      @openMetaDataEdit="openMetaDataEdit"
      @update-selected-all="updateSelectAll"
    >
      <template #avatar="{ item }">
        <BaseIcon
          v-if="item.type === 'FOLDER'"
          name="eva-folder"
          color="amber"
        />
        <FileIcon
          v-if="item.type === 'FILE'"
          :mime-type="getFileType(item.name)"
          class="file-icon col-auto"
          style="height: 25px; width: 25px"
          @click.stop="handleOpenFile(item.id)"
        />
        <BaseIcon
          v-if="item.isPassword"
          v-tooltip.top="'Protected'"
          name="eva-lock"
          color="red"
          class="icon col-auto"
        />
        <BaseIcon
          v-if="item.maxVersion === 1"
          v-tooltip.top="'Primary Document'"
          name="mdi-tag"
          color="primary"
          class="icon"
        />
        <BaseIcon
          v-if="item.maxVersion === 0"
          v-tooltip.top="'V' + item.version"
          name="mdi-tag-outline"
          color="secondary"
          class="icon"
        />
      </template>
      <!-- <template #title="{ item }">
        {{ item.name }}
      </template> -->
      <template #revise="{ item }">
        <div
          v-if="item.isNewVersion && item.stageStatus === 'Approved'"
          @click.stop="$emit('revise', item.id)"
        >
          <template v-if="item.isNewVersion">
            <q-chip outline color="brown" class="chips brown-hover">
              SUBMIT REVISE
            </q-chip>
          </template>
        </div>
      </template>
      <template #status="{ item }">
        <div v-if="item.stageStatus">
          <template v-if="item.stageStatus === 'Approved'">
            <q-chip outline color="green" class="chips green-hover">
              <q-avatar icon="mdi-check" color="green" text-color="white" />
              {{ item.stageStatus }}
            </q-chip>
          </template>
          <template v-else-if="item.stageStatus === 'Rejected'">
            <q-chip outline color="red" class="chips red-hover">
              <q-avatar icon="mdi-close" color="red" text-color="white" />
              {{ item.stageStatus }}
            </q-chip>
          </template>
          <template v-else>
            <q-chip outline color="orange" class="chips orange-hover ellipsis">
              <q-avatar
                icon="mdi-information-variant"
                color="orange"
                text-color="white"
              />
              {{ item.stageStatus }}
            </q-chip>
          </template>
        </div>
      </template>
      <template #actions="{ item }">
        <div
          v-if="!item.checkinStatus || item.checkinStatus === 1"
          class="row"
          :style="'width: ' + calcButtonWidth"
        >
          <BaseActionButton
            v-if="visibleDownload"
            v-tooltip.top="'download'"
            icon="mdi-download-outline"
            color="primary"
            is-flat
            no-border
            class="icon"
            @click.stop="$emit('download', item.id)"
          />

          <BaseActionButton
            v-if="visiblePrint"
            v-tooltip.top="'print'"
            is-flat
            no-border
            icon="eva-printer-outline"
            color="primary"
            @click.stop="$emit('print', item.id)"
          >
          </BaseActionButton>
          <BaseActionButton
            v-if="visibleEmail"
            v-tooltip.top="'Email Sharing'"
            is-flat
            no-border
            icon="mdi-email-outline"
            color="primary"
            @click.stop="$emit('email', item)"
          >
          </BaseActionButton>
          <BaseActionButton
            v-if="visibleDelete"
            is-flat
            no-border
            icon="eva-trash-2-outline"
            color="primary"
            @click.stop="$emit('deleteFile', item.id)"
          />
        </div>
        <div
          v-if="
            item.checkinStatus === 2 &&
            item.checkinBy === $store.state.session.email
          "
          class="row"
        >
          <q-chip
            outline
            color="orange"
            clickable
            class="chips orange-hover ellipsis"
            @click="showCheckinUploadFile(item)"
          >
            <q-avatar
              icon="mdi-file-upload-outline"
              color="orange"
              text-color="white"
            />
            Checkin
          </q-chip>
        </div>
      </template>
      <!-- Thumbnail -->
      <template #thumbnail="{ item }">
        <div
          v-show="showThumbnail && itemId === item.id && repositoryId"
          class="shadow-3 fixed-left thumb-img"
          :style="{ top: mouseY, left: mouseX }"
        >
          <img v-show="isLoaded" :src="thumbnailLink" @load="onImgLoad" />

          <q-spinner
            v-if="!isLoaded"
            color="primary-3"
            size="48px"
            class="spinner"
          />
        </div>
      </template>
      <!-- Thumbnail -->
    </ItemList>
  </div>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";
import FileIcon from "@/components/common/FileIcon.vue";

export default {
  name: "EntityList",

  components: { ItemList, FileIcon },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    entities: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    repositoryId: {
      type: String,
      required: true,
    },

    hasMetaDataEdit: {
      type: Boolean,
      required: false,
    },

    visibleDownload: {
      type: Boolean,
      default: false,
    },

    visiblePrint: {
      type: Boolean,
      default: false,
    },

    visibleEmail: {
      type: Boolean,
      default: false,
    },

    checkbox: {
      type: Boolean,
      default: false,
    },

    visibleDelete: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showThumbnail: false,
      thumbnailLink: "",
      itemId: "",
      mouseX: "",
      mouseY: "",
      isLoaded: false,
    };
  },

  computed: {
    calcButtonWidth() {
      let count = 0;

      if (this.visibleDownload) {
        count += 1;
      }

      if (this.visibleEmail) {
        count += 1;
      }
      if (this.visiblePrint) {
        count += 1;
      }

      if (this.visibleDelete) {
        count += 1;
      }

      let width;

      if (count === 1) {
        width = "37px";
      }

      if (count === 2) {
        width = "85px";
      }

      if (count === 3) {
        width = "110px";
      }

      if (count === 4) {
        width = "145px";
      }

      return width;
    },
  },

  methods: {
    handleSelect(entityId, entity) {
      if (entity[0].type === "FILE") {
        this.$emit("view", entityId);
      } else {
        this.$emit("select", entityId, entity);
      }
    },

    handleEdit(entityId) {
      this.$emit("edit", entityId);
    },

    handleDelete(entityId) {
      this.$emit("delete", entityId);
    },

    handleRestore(entityId) {
      this.$emit("restore", entityId);
    },

    handleMore(entityId) {
      this.$emit("more", entityId);
    },

    getFileType(fileName) {
      return fileName.split(".").reverse()[0];
    },

    handleOpenFile(itemId) {
      this.$emit("view", itemId);
    },

    showFileThumbnail(file, event) {
      //let ext = file.name.split(".").pop();
      //if (ext.toLowerCase() === "pdf") {
      let bodyHeight = this.$q.screen.height;
      if (bodyHeight - event.pageY < 300) {
        this.mouseY = bodyHeight - 300 + "px";
      } else {
        this.mouseY = event.pageY + 20 + "px";
      }
      if (file.name.length < 35) {
        this.mouseX = "400px";
      } else {
        this.mouseX = `${400 + (file.name.length - 35) * 7}px`;
      }
      this.itemId = file.id;
      this.thumbnailLink = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${file.id}/1`;
      this.showThumbnail = true;
      //}
    },

    hideThumbnail() {
      this.thumbnailLink = "";
      this.showThumbnail = false;
      this.isLoaded = false;
    },

    onImgLoad() {
      this.isLoaded = true;
    },

    showCheckinUploadFile(item) {
      this.$emit("showCheckin", item);
    },

    openMetaDataEdit(data, column) {
      this.$emit("openMetaDataEdit", data, column);
    },

    updateSelectAll(value) {
      this.$emit("update-selected-all", value);
    },
  },
};
</script>

<style lang="scss">
#entityList {
  #item-list {
    table {
      // width: 100%;
      th:not(.icon),
      th:not(.action),
      td:not(.icon),
      td:not(.action) {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      th.icon,
      td.icon {
        width: 36px;
        white-space: nowrap;
      }

      td:hover {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }

      th.action,
      td.action {
        width: 90px;
        white-space: nowrap;
      }

      th.file-mark-icon,
      td.file-mark-icon {
        width: 15px !important;
      }

      // tbody tr td.sticky {
      //   position: static;
      // }
    }
  }

  .chips {
    margin: 0px !important;
    font-size: 12px !important;
    margin-right: 10px !important;
  }

  .pink-hover:hover {
    background: $pink-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .brown-hover:hover {
    background: $brown-2 !important;
  }

  #action-btn.small {
    width: 22px !important;
    height: 22px !important;
  }

  .thumb-img {
    height: 200px;
    width: 200px;
    border-radius: 10px;
    background: white;
    text-align: center;

    img {
      width: 100%;
    }

    .spinner {
      height: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
// #item-list table tbody tr td.sticky {
//   position: static;
// }
//
</style>
